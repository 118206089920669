import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

export default () => (
  <ThemeToggler>
    {({ theme, toggleTheme }) => (
    <label id="switch" class="switch">
      <input type="checkbox"
          onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
          checked={theme === 'dark'}
          id="slider"
          aria-label="darkmode"
      />
          <span class="slider round"></span>
    </label>
    )}
  </ThemeToggler>
)